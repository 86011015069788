import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Link, graphql, useStaticQuery } from 'gatsby';
import PostCard from '@components/PostCard';
import Grid from '@material-ui/core/Grid';
import SideBar from '@components/SideBar';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: '1 0 auto',
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'center',
  },
  PostsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: green,
    },
  },
  sideBar: {
    display: 'flex',
    background: theme.palette.secondary.main,
    maxWidth: theme.spacing(40),
    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {

    },
    height: theme.spacing(37.5),
    margin: `${theme.spacing(1)}px auto`,
  },
  paper: {
    display: 'flex',
    background: theme.palette.secondary.main,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Content = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
          edges {
            node {
              frontmatter {
                title
                date
                tags              
              }
              excerpt
              fields {
                slug
              }
            }
          }
      }
    }
`);
  const items = data.allMarkdownRemark.edges;
  const classes = useStyles();
  return (
    <Box py={1} className={classes.root}>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box display="block" className={classes.PostsList}>
          {items.map((item) => {
            const { excerpt: summary } = item.node;
            const { title, date, tags } = item.node.frontmatter;
            const { slug } = item.node.fields;
            return (
              <Link to={`/blog/${slug}`}>
                <PostCard title={title} date={date} summary={summary} tags={tags} />
              </Link>
            );
          })}
        </Box>
      </Grid>
      {/* <Grid item xs={12} sm={12} lg={3}>
        <SideBar />
      </Grid> */}
    </Box>
  );
};

export default Content;
