import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tag from '@components/Tag';

const useStyles = makeStyles((theme) => {
  console.log(theme.spacing());
  return {
    sideBar: {
      display: 'flex',
      background: theme.palette.secondary.main,
      maxWidth: theme.spacing(40),
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        // width: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: green,
      },
      height: theme.spacing(37.5),
      margin: `${theme.spacing(1)}px auto`,
    },
    paper: {
      display: 'flex',
      background: theme.palette.secondary.main,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

const SideBar = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.sideBar}>
      <Grid item xs={12} sm={12} lg={12}>
        <Box m={1} my={5}>
          <Typography variant="subtitle1" gutterBottom>
            Keywords
          </Typography>
          <Box my={1} display="flex" flexWrap="wrap">
            <Tag name="Wso2" />
            <Tag name="React" />
            <Tag name="API" />
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
};

export default SideBar;
