import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(30),
    color: 'white',
    height: theme.spacing(12),
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 5,
    color: 'rgba(255, 255, 255, 0.6)',
  },
  button: {
    color: 'white',
  },
  actions: {
    padding: 0,
    float: 'right',
  },
}));
// height: 1px;
//     background: linear-gradient(to right,var(--green1),transparent)
const PostCard = (props) => {
  const {
    title, date, summary, tags,
  } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: 0, textAlign: 'center' }}>
        <Typography variant="h6">
          {title}
        </Typography>
        <Box display="inline-flex">
          <Typography className={classes.pos} variant="subtitle1" color="textSecondary">
            {date}
          </Typography>

          <Box ml={1}>
            <Typography className={classes.pos} variant="subtitle1" color="textSecondary">
              {tags}
            </Typography>
          </Box>
        </Box>
        {/* <Typography className={classes.pos} color="textSecondary">
          {summary}
        </Typography> */}
        {/* <CardActions className={classes.actions}>
          <Button className={classes.button} size="small">Learn More</Button>
        </CardActions> */}
      </CardContent>
    </Card>
  );
};

export default PostCard;
