import React, { useContext, useEffect } from 'react';
import Content from '@components/Content';
import Layout from '@components/Layout';
import './main.css';
import 'prismjs/themes/prism-tomorrow.css';
import SEO from '@components/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import { GlobalStateContext } from '@components/Context';

export default function Home() {
  const context = useContext(GlobalStateContext);
  const data = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          title,
          description,
          keywords,
          author,
          siteUrl,
          image,
          twitterUsername,
        }
      }
    }
  `);
  useEffect(() => {
    context.setData(data.site.siteMetadata);
  }, []);
  const {
    title, description, keywords, author, siteUrl, image, twitterUsernname,
  } = data.site.siteMetadata;
  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        author={author}
        url={siteUrl}
        image={image}
        type="website"
        locale="en_US"
        twitterUsernname={twitterUsernname}
        title={title}
      />
      <Content />
    </Layout>
  );
}
